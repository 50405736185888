// modules
import {ProductListCommon} from './product-list/product-list-common';
import {ProductListFilters} from "./product-list/product-list-filters";

export class ProductList {
	constructor() {
		this.baseUrl = null;
		// init modules
		this.productListCommon = new ProductListCommon();
		this.productListFilters = new ProductListFilters();
	}

	initWhenDomReady() {
		let _this = this;
		// wait until the DOM is ready, otherwise querySelector results are not correct
		window.addEventListener('DOMContentLoaded', () => {
			_this.initDirectAlways();
			_this.initDirectOnce();
		});
	}

	/**
	 * This method should be called only once when page is load.
	 */
	initDirectOnce(){
		this.formattedDescription(
			140,
			".js-cover-description",
			window.Danfil.showMore
		);
	}

	/**
	 * This method should be called always when snippet with products is loaded.
	 */
	initDirectAlways() {
		this.baseUrl = location.origin + window.Danfil.urlRoot;

		// product tiles are clickable
		this.productListCommon.initDirectAlways();

		this.productListFilters.init();
	}

	formattedDescription(maxLength, textSelector, buttonText) {
		const descriptionElement = document.querySelector(textSelector);

		if (
			null !== descriptionElement &&
			0 !== descriptionElement.innerHTML.length
		) {
			let description = descriptionElement.innerHTML;
			const descriptionChilds = Array.prototype.slice.call(
				descriptionElement.childNodes
			);
			let descriptionLength = 0;
			let newDescription = "";
			let pattern = null;
			const showMoreButton =
				' <a class="js-show-more" href="#">' + buttonText + "</a>";
			let truncatedText = "";

			descriptionChilds.forEach(function (child) {
				pattern = new RegExp(
					"^(.{" + (maxLength - descriptionLength) + "}[^\\s]*).*",
					"g"
				);

				if (descriptionLength < maxLength) {
					truncatedText = child.textContent
						.replace(/\s+/g, " ")
						.replace(pattern, "$1"); // remove line breaks and get full words from text by length
					descriptionLength += truncatedText.length;

					// plain text without html
					if (undefined === child.outerHTML) {
						newDescription += truncatedText;
					} else {
						const clonedChild = child.cloneNode(true);

						clonedChild.textContent = truncatedText;
						newDescription += clonedChild.outerHTML;
					}
				}
			});

			if (truncatedText.length !== descriptionElement.innerHTML.length) {
				descriptionElement.innerHTML = newDescription;
				descriptionElement.innerHTML += showMoreButton;

				const showMoreBtn = document.querySelector(".js-show-more");

				// click on show more
				showMoreBtn.addEventListener('click', function (e) {
					e.preventDefault();
					e.stopPropagation();

                    descriptionElement.innerHTML = description;
                    descriptionElement.classList.remove('c-category-cover__desc--truncated');
                });
            }
        }
    }
}
