export class UserDeviceDetection {
    constructor() {
        this.mobile = null;
    }

    isMobile() {
        if (this.mobile === null) {
            this.mobile = document.querySelector('html').classList.contains('mobile');
        }

        return this.mobile;
    }
}
