// npm libs
import axios from 'axios';
// functions
import {elementClassAdd, elementClassRemove, elementHasClass, elementHide, elementShow} from '../features';
// helpers
import {Errors} from "../../helpers/errors";
// modules
import {AjaxContentObserver} from '../ajax-content-observer';
import {ProductListCommon} from './product-list-common';
import {Loader} from "../loader";
import {UserDeviceDetection} from "../user-device-detection";

export class ProductListFilters {
    constructor() {
        // init helpers
        this.errors = new Errors();
        // init modules
        this.ajaxContentObserver = new AjaxContentObserver();
        this.productListCommon = new ProductListCommon();
        this.loader = new Loader();
        this.userDeviceDetection = new UserDeviceDetection();

        // internal settings / buffers / ...
        this.baseUrl = null;
        this.$productListSnippet = null;
        this.$topFiltersButtons = null;
        this.$topFiltersDropdowns = null;

        // classes
        this.jsClassTopFiltersOpened = '-js-top-filter-opened';
        this.jsClassPopupFiltersOpened = '-js-popup-filter-opened';
    }

    /**
     * This must be called when page is already loaded (DOM is ready).
     * So here is no need to check the DOM ready statement.
     */
    init() {
        this.baseUrl = location.origin + window.Danfil.urlRoot;

        this.initSelectors();

        /*
            top-filters
         */
        // init only once - handles not dependent parts on snippets
        this.topFiltersInitOnce();
        // init at beginning and every snippet load
        this.topFiltersInitAlways(true);

        /*
            popup-filters
         */
        this.popupFiltersInit(true);
    }

    /**
     * must be called always!
     */
    initSelectors() {
        // main wrapper
        this.$productListSnippet = document.querySelector(".snippet-product-list");

        // top-filters
        this.$topFiltersWrapper = document.querySelector(".l-filter--top-filters");
        this.$topFiltersButtons = document.querySelectorAll(".js-filter-button");
        this.$topFiltersDropdowns = document.querySelectorAll(".c-filter-dropdown:not(.c-filter-dropdown--fullscreen-modal)");

        // popup-filters
        this.$popupFiltersWrapper = document.querySelector(".c-filter-fullscreen-modal");
        this.$popupFiltersDialogClose = document.querySelectorAll(".c-filter-fullscreen-modal__close-wrapper");
        this.$popupFiltersSubmitButton = document.querySelector(".c-filter-fullscreen-modal__footer-wrapper button");
    }

    /**
     * This method must be called always when snippet is finished for top-filters!
     */
    topFiltersInitAlways(firstCall = false) {
        this.initSelectors();

        // top-filters
        this.topFiltersAddListeners();

        // popup-filters (only on 2> call)
        if (firstCall === false) {
            this.popupFiltersInitAlways();
            this.popupFiltersInitPartsInProductList();
        }
        this.popupFiltersInitPartsModal();
    }

    /**
     * This method must be called always when snippet is finished for popup-filters!
     */
    popupFiltersInit(firstCall = false) {
        this.initSelectors();

        // top-filters (only on 2> call)
        if (firstCall === false) {
            this.topFiltersAddListeners();
        }

        // popup-filters
        this.popupFiltersInitAlways();
        this.popupFiltersInitPartsInProductList();
        // Do not call popupFiltersInitPartsModal here because elements of these listeners were not changed!
    }

    /* ------------------------------------------------------------
        ↓↓↓↓ TOP FILTERS ↓↓↓↓
     ------------------------------------------------------------*/

    topFiltersInitOnce() {
        let _this = this;

        // Close filters on outer clicks
        document.body.addEventListener('click', function (event) {
            // do not apply on filter
            if (
                elementHasClass(_this.$productListSnippet, _this.jsClassTopFiltersOpened)
                && (
                    // its clicked anything in the filter container
                    event.target.closest('.c-filter-fullscreen-modal') !== null
                    // DESKTOP: or it is clicked inside the dropdown
                    || event.target.closest('.c-filter-dropdown') !== null
                )
            ) {
                return;
            }

            _this.topFiltersCloseFilters();
        });
    }

    topFiltersAddListeners() {
        let _this = this;

        // open top-filters
        if (this.$topFiltersButtons !== null) {
            if (this.userDeviceDetection.isMobile()) {
                // on mobile device open directly all filters

                [...this.$topFiltersButtons].forEach(el => el.addEventListener('click', function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    _this.popupFiltersOpen(el);
                }));

            } else {
                // on desktop open required top filter
                [...this.$topFiltersButtons].forEach(el => el.addEventListener('click', function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    // close other filters - only one can be opened at one time
                    _this.topFiltersCloseFilters();
                    // open required filter
                    _this.topFiltersOpenOneFilter(e.currentTarget);
                }));
            }
        }

        // close one filter
        [...document.querySelectorAll('.c-filter-dropdown__close')].forEach(el => el.addEventListener('click', function (e) {
            e.preventDefault();
            // just call "close other filters"
            _this.topFiltersCloseFilters();
        }));

        this.topFiltersAddListenersSnippets();
    }

    /**
     * Snippets handling
     */
    topFiltersAddListenersSnippets() {
        let _this = this;

        // filters to be selected (inputs)
        let jsFilterItems = _this.$topFiltersWrapper.querySelectorAll('.c-products-filter__item');
        if (jsFilterItems !== null) {
            [...jsFilterItems].forEach((elm) => elm.addEventListener('click', function (e) {
                e.preventDefault();
                let input = elm.querySelector('input');
                let url = input.getAttribute('data-url');
                _this.topFiltersUpdateProductListByAjaxContent(
                    'get',
                    new URL(_this.baseUrl + url),
                    'product_list_update'
                );
                
                // Call datalayer event
                window.dataLayer.push({
					'event': 'filter',
					'filterCategory': elm.closest('.c-filter-dropdown__list').getAttribute('data-filter-category'),
					'filterValue': input.getAttribute('data-filter-name')
				})

                // push the url to the history, so browser back link will work properly
                if (history.pushState) {
                    history.pushState(null, null, url);
                }
            }));
        }

        // selected filters (remove links)
        let jsSelectedFilterItems = _this.$topFiltersWrapper.querySelectorAll('.c-selected-filter__item');
        if (jsSelectedFilterItems !== null) {
            [...jsSelectedFilterItems].forEach((elm) => elm.addEventListener('click', function (e) {
                e.preventDefault();
                let url = elm.getAttribute('href');
                _this.topFiltersUpdateProductListByAjaxContent(
                    'get',
                    new URL(_this.baseUrl + url),
                    'product_list_update'
                );

                // push the url to the history, so browser back link will work properly
                if (history.pushState) {
                    history.pushState(null, null, url);
                }
            }));
        }
    }


    topFiltersOpenOneFilter($element) {
        let expanded = $element.getAttribute('aria-expanded') === 'true';
        if (expanded) {
            $element.setAttribute('aria-expanded', 'false');
            $element.nextElementSibling.setAttribute('hidden', 'hidden');
        } else {
            $element.setAttribute('aria-expanded', 'true');
            $element.nextElementSibling.removeAttribute('hidden');
        }

        elementClassAdd(this.$productListSnippet, this.jsClassTopFiltersOpened);
    }

    topFiltersCloseFilters() {
        let _this = this;

        // nothing to close
        if (!elementHasClass(_this.$productListSnippet, _this.jsClassTopFiltersOpened)) {
            return;
        }

        // top-filters: reset aria-expanded
        if (_this.$topFiltersButtons !== null) {
            [..._this.$topFiltersButtons].forEach((elm) => {
                elm.setAttribute('aria-expanded', false);
            });
        }

        // top-filters: hide dropdowns
        if (_this.$topFiltersDropdowns.length > 0) {
            [..._this.$topFiltersDropdowns].forEach((elm) => {
                elm.setAttribute('hidden', 'hidden');
            });
        }

        // ... and finally remove indicator class
        elementClassRemove(this.$productListSnippet, this.jsClassTopFiltersOpened);
    }

    /**
     * @param {String} method - post/get
     * @param {URL} ajaxUrl - ajax URL to be called
     * @param {String} snippetName
     */
    topFiltersUpdateProductListByAjaxContent(
        method,
        ajaxUrl,
        snippetName
    ) {
        let _this = this;

        // save snippet name to global scope
        _this.snippetName = snippetName;

        ajaxUrl.searchParams.append('snippet', snippetName);
        ajaxUrl.searchParams.append('ajax_content', '1');

        // show loader in the product basics
        _this.loader.showLoader(_this.$productListSnippet);

        // remember what filters were opened
        let jsOpenedFilters = document.querySelectorAll('.c-filter-dropdown:not(.c-filter-dropdown--fullscreen-modal)');
        let jsOpenedFiltersNames = [];
        if (jsOpenedFilters !== null) {
            [...jsOpenedFilters].forEach((elm) => {
                // element does not have `hidden` attribute (otherwise it has value '')
                if (elm.getAttribute('hidden') === null) {
                    jsOpenedFiltersNames.push(elm.id);
                }
            });
        }

        axios({
            method: method,
            url: ajaxUrl.toString(),
            headers: {
                // Always include an `X-Requested-With` header in every AJAX request, to protect against CSRF attacks.
                'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'application/octet-stream; charset=utf-8'
            },
            data: null,
        })
            .then(function (response) {
                // fetch snippet data
                let snippetData = response.data[_this.snippetName];
                if (typeof snippetData === 'undefined') {
                    throw _this.errors.errors.snippetContentError;
                }

                // extract required data and insert them to DOM
                let ajaxSnippetHtml = (new DOMParser()).parseFromString(snippetData.snippet, 'text/html');
                // container in the modal where product will be updated
                let $newContent = ajaxSnippetHtml.querySelector('.snippet-product-list');
                if ($newContent !== null) {

                    let snippetFilters = $newContent.querySelectorAll('.c-filter-dropdown');
                    [...snippetFilters].forEach((elm) => {
                        if (jsOpenedFiltersNames.includes(elm.id)) {
                            // remove hidden attribute from actually opened filter
                            elm.removeAttribute('hidden');
                        }
                    });

                    _this.$productListSnippet.innerHTML = $newContent.innerHTML;
                }

                /*
                    Finally for the newly updated content must be re-init javascript.
                    Important:  top-filters and popup-filters can be used in same session. Therefor we need to update
                                HTML content for both of them.
                                Top filters update whole main `snippet-product-list`.
                                So we need to re-init whole popup-filters.
                 */
                // central method for re-init JS in this class
                _this.topFiltersInitAlways();
                // product list commons
                _this.productListCommon.initDirectAlways();
            }).finally(function () {
            _this.loader.hideLoader(_this.$productListSnippet);

            // for newly inserted HTML must be re-init listeners
            // (this will re-init dropdowns and other which we need here)
            _this.ajaxContentObserver.updateObserverElement();
        });
    }


    /* ------------------------------------------------------------
        ↓↓↓↓ POPUP FILTERS ↓↓↓↓
     ------------------------------------------------------------*/

    popupFiltersInitPartsInProductList() {
        let _this = this;

        // Show popup-filters (using button "More filters")
        let jsFilterModalButton = document.querySelector('.js-show-filter-modal');
        if (jsFilterModalButton !== null) {
            jsFilterModalButton.addEventListener('click', function (e) {
                e.preventDefault();
                _this.popupFiltersOpen();
            });
        }

        document.onkeydown = function(evt) {
            evt = evt || window.event;
            let isEscape = false;
            if ("key" in evt) {
                isEscape = (evt.key === "Escape" || evt.key === "Esc");
            } else {
                isEscape = (evt.keyCode === 27);
            }
            if (isEscape) {
                _this.popupFiltersClose();
            }
        };
    }

    /**
     * This method must be called always when popup-filters are fully reloaded.
     * It primarily concerns replacing snippet from top-filters.
     */
	popupFiltersInitPartsModal() {
		let _this = this;

		// close popup-filters by direct click on close buttons
		if (_this.$popupFiltersDialogClose !== null) {
			[..._this.$popupFiltersDialogClose].forEach((el) =>
				el.addEventListener('click', function (e) {
					e.preventDefault();
					_this.popupFiltersClose();
				})
			);
		}

		// close popup-filters by click outside the popup filter
		if (_this.$popupFiltersWrapper !== null) {
			_this.$popupFiltersWrapper.addEventListener('click', function (e) {
				if (elementHasClass(e.target, 'c-filter-fullscreen-modal__wrapper')) {
                    e.preventDefault();
					_this.popupFiltersClose();
				}
			});
		}

		// close popup-filters by clicking on "show (x)" products
		if (_this.$popupFiltersSubmitButton !== null) {
			_this.$popupFiltersSubmitButton.addEventListener('click', function (e) {
				e.preventDefault();
                _this.popupFiltersClose();
			});
		}

		// filter heading toggling the list of values in filter
        // (only in fullscreen modal)
		let $filterHeadings = document.querySelectorAll(".c-filter-fullscreen-modal .c-filter-dropdown__heading");
		if ($filterHeadings !== null) {
			[...$filterHeadings].forEach((el) =>
				el.addEventListener('click', function (e) {
					e.preventDefault();
					e.stopPropagation();

					if (el.hasAttribute('data-closed')) {
						// filter values are closed -> open them
						el.removeAttribute('data-closed');
						elementShow(el.nextElementSibling);
					} else {
						// filter values are opened -> close them
						el.setAttribute('data-closed', 1);
						elementHide(el.nextElementSibling);
					}
				})
			);
		}
	}

	popupFiltersInitAlways() {
		this.popupFiltersAddListenersSnippets();
	}

    /**
     * @param {Element|null} element
     */
    popupFiltersOpen(element) {
        elementShow(this.$popupFiltersWrapper, true);
        elementClassAdd(this.$productListSnippet, this.jsClassPopupFiltersOpened);

        if (element !== undefined && element !== null) {
            let clickedFilter = element.getAttribute('aria-controls');
            let targetElm = document.querySelector('.c-filter-dropdown--fullscreen-modal#' + clickedFilter);  // reference to scroll target
            targetElm.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }
    }

    popupFiltersClose() {
        elementHide(this.$popupFiltersWrapper, true);
        elementClassRemove(this.$productListSnippet, this.jsClassPopupFiltersOpened);
    }

    popupFiltersAddListenersSnippets() {
        let _this = this;

        // filters to be selected (inputs)
        let jsFilterItems = this.$popupFiltersWrapper.querySelectorAll('.c-products-filter__item');
        if (jsFilterItems !== null) {
            [...jsFilterItems].forEach((elm) => elm.addEventListener('click', function (e) {
                e.preventDefault();
                let input = elm.querySelector('input');
                let url = input.getAttribute('data-url');
                _this.popupFiltersUpdateProductListByAjaxContent(
                    'get',
                    new URL(_this.baseUrl + url),
                    'product_list_update'
                );

                // Call datalayer event
                window.dataLayer.push({
					'event': 'filter',
					'filterCategory': elm.closest('.c-filter-dropdown__list').getAttribute('data-filter-category'),
					'filterValue': input.getAttribute('data-filter-name')
				})

                // push the url to the history, so browser back link will work properly
                if (history.pushState) {
                    history.pushState(null, null, url);
                }
            }));
        }
    }

    /**
     * @param {String} method - post/get
     * @param {URL} ajaxUrl - ajax URL to be called
     * @param {String} snippetName
     */
    popupFiltersUpdateProductListByAjaxContent(
        method,
        ajaxUrl,
        snippetName
    ) {
        let _this = this;

        // save snippet name to global scope
        _this.snippetName = snippetName;

        ajaxUrl.searchParams.append('snippet', snippetName);
        ajaxUrl.searchParams.append('ajax_content', '1');

        // show loader in the product basics
        _this.loader.showLoader(_this.$popupFiltersWrapper);

        axios({
            method: method,
            url: ajaxUrl.toString(),
            headers: {
                // Always include an `X-Requested-With` header in every AJAX request, to protect against CSRF attacks.
                'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'application/octet-stream; charset=utf-8'
            },
            data: null,
        })
            .then(function (response) {
                // fetch snippet data
                let snippetData = response.data[_this.snippetName];
                if (typeof snippetData === 'undefined') {
					throw _this.errors.errors.snippetContentError;
                }

                // extract required data and insert them to DOM
                let ajaxSnippetHtml = (new DOMParser()).parseFromString(snippetData.snippet, 'text/html');
                // container in the modal where product will be updated
                let $newContent = ajaxSnippetHtml.querySelector('.snippet-product-list');
                if ($newContent !== null) {

					// update all filters values
					let ajaxFilters = $newContent.querySelectorAll('.c-filter-dropdown--fullscreen-modal');
					if (ajaxFilters !== null) {
						[...ajaxFilters].forEach((elm) => {
							let ajaxFilterId = elm.id;

							let actualFilter = _this.$popupFiltersWrapper.querySelector('.c-filter-dropdown--fullscreen-modal#' + ajaxFilterId);
							if (actualFilter !== null) {
								actualFilter.innerHTML = elm.innerHTML;
							}
						});
					}

					// update total products to show
					let ajaxButtonSubmit = $newContent.querySelector('.c-filter-fullscreen-modal__footer-wrapper button');
					if (ajaxButtonSubmit !== null) {
                        _this.$popupFiltersSubmitButton.innerHTML = ajaxButtonSubmit.innerHTML;
					}

                    // update products list in the background (contains also top-filters)
                    let ajaxTopFiltersAndProductList = $newContent.querySelector('.page-wrap.l-products');
                    if (ajaxTopFiltersAndProductList !== null) {
                        document.querySelector('.page-wrap.l-products').innerHTML = ajaxTopFiltersAndProductList.innerHTML;
                    }
                }

                /*
                    Finally for the newly updated content must be re-init javascript.
                    Important:  top-filters and popup-filters can be used in same session. Therefor we need to update
                                HTML content for both of them.
                                This means we need also re-init JS for both of them!
                 */
                // central method for re-init JS in this class
                _this.popupFiltersInit();

                // product list commons
                _this.productListCommon.initDirectAlways();
            }).finally(function () {
                _this.loader.hideLoader(_this.$popupFiltersWrapper);

                // for newly inserted HTML must be re-init listeners
                // (this will re-init dropdowns and other which we need here)
                _this.ajaxContentObserver.updateObserverElement();
            }).catch(function (error) {
                // HTTP response code 499 is OK from our side.
                // Probably caused by GoogleBot (or some other crawler) who is firing too much event on the server.
                if (error.response?.status === 499) {
                    console.error('Client has closed the connection abruptly before the server could respond to the request.', error.response.data);
                    return undefined;
                }

                // something else happened -> treat that as standard exception
                throw error;
            });
    }
}
